import {
	AUTH_TOKEN,
	AUTHENTICATED,
	MULTIPROJECTPROMPT,
	SECONDARY_AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED
} from '../constants/Auth';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  secondaryApp: null,
  hiddenList: true,
  userDisabled: true,
  passDisabled: true,
  projectsList: [],
  projectsFirebase: [],

}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token
			}
		case MULTIPROJECTPROMPT:
			return {
				...state,
				loading: false,
				hiddenList: false,
				projectsList: action.payload.projectsList,
				projectsFirebase: action.payload.projectsFirebase,
				userDisabled: true,
				passDisabled: true,
			}
		case SECONDARY_AUTHENTICATED:
			return {
				...state,					
				secondaryApp: action.secondaryApp
			}
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: action.message,
				messageType:action.messageType,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false,
				hiddenList: true,
				userDisabled: true,
				passDisabled: true,
				projectsList: [],
				projectsFirebase: [],
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth