import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNINSECONDARY,
	SIGNOUT,
	SIGNUP,
	FORGET,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	multiprojectPrompt,
	secondaryAuthenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService'

let firebaseObject = [] // Arreglo de proyectos de usuario logueandose

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password, project} = payload;
		// Si no hay project, es step 0
		console.log(project)
		if (!project) {
			try {
				const user = yield call(FirebaseService.signInEmailRequest, email, password)
				if (user.message) {
					yield put(showAuthMessage(user.message))
				} else {
					let isMultiproyecto = false
					let projectsArray = []
					// window.localStorage.setItem('loginStep', '1')
					//Acá está logueado a la DB primaria. Capturar datos de DB secundaria.
					const snap = yield call(FirebaseService.getPrimaryUserData, user.user.uid)				
					console.log('user', user)
					localStorage.setItem(AUTH_TOKEN, user.user.uid)
					localStorage.setItem("auth_email", email)
					const primary = snap.val()
					// Si primary es null, el usuario fue borrado de la DB primaria
					if(primary === null){
						console.log("primary nulo", primary)
						// No continuar el login
						// Avisar que el usuario no existe
						// Permanecer en el login
						yield put(showAuthMessage("Email no registrado. Por favor, intente de nuevo.", "error"));
						return true
					}
					// Sobreescribe nombres de roles
					if("Rol" in primary){
						if(primary.Rol === "Profesor") primary.showRol = "Administrador"
						if(primary.Rol === "Alumno") primary.showRol = "Usuario"
					}
					localStorage.setItem("PRIMARY_DATA", JSON.stringify(primary))
					console.log('PRIMARY DATA', primary)
					// Datos de DB primaria obtenidos. Ver si es arreglo o sólo tiene 1 proyecto.

					let firebaseData = {}
					if (Array.isArray(primary)) {
						// Es arreglo de Objetos
						// Se deben agrupar los datos Firebase de todas las instacias
						firebaseData = []
						primary.map(projectItem => {
						  firebaseData.push(projectItem.Firebase)
						  return true
						})
				
						firebaseObject = firebaseData
						isMultiproyecto = true
						// Recorre cada proyecto dentro del arreglo para extraer su uid
						firebaseData.map(projectItem => {
						  if (projectItem.projectId) {
							projectsArray.push(projectItem.projectId)
							return true
						  }
						  return true
						})
					  } else {
						firebaseData = primary.Firebase
						console.log('firebase data es objeto or unexpected', firebaseData)
						isMultiproyecto = false
					  }

					  // Ya se ha determinado si el usuario tiene uno o más proyectos.
					  // Si solo tiene un proyecto
					if (!isMultiproyecto) {
						// loginStep = 0
						window.localStorage.setItem('loginStep', '0')
						window.localStorage.setItem('projectRef', '')
						console.log('1 proyecto')
						console.log('primary rol', primary.Rol)
						console.log('primary nombre', primary.Nombre)
						localStorage.setItem("user_role", primary.Rol)
						localStorage.setItem("user_name", primary.Nombre)
						localStorage.setItem(AUTH_TOKEN, user.user.uid)
						localStorage.setItem("auth_email", email)
						// Log into secondary DB
						signInSecondary(primary);
						yield put(authenticated(user.user.uid));
					} 
					// Si es multiproyecto
					else {
						console.log('Multiproyecto')
						window.localStorage.setItem('projectRef', '')
						yield put(multiprojectPrompt({							
							projectsList: projectsArray,
							projectsFirebase: firebaseData
						}));						
					}
				}
			} catch (err) {
				yield put(showAuthMessage(err));
			}
		}
		// Hay project, es step 1 de multiproyecto
		else{
			let firebaseConnection = ''
			let projectIndex = 0
			let finalProjectIndex = 0
			// Recorre el arreglo de proyectos obtenido en step 0
			firebaseObject.map(firebaseItem => {
			if (firebaseItem.projectId === project) {
				firebaseConnection = firebaseItem
				window.localStorage.setItem('projectRef', `${projectIndex.toString()}/`)
				finalProjectIndex = projectIndex
				return true
			}
			projectIndex += 1
			return true
			})
			// loginStep = 0
			window.localStorage.setItem('loginStep', '0')

			// Log into secondary DB			
			const allProjecstData = JSON.parse(localStorage.getItem("PRIMARY_DATA"))
			console.log("allProjecstData",allProjecstData)
			localStorage.setItem("PRIMARY_DATA", JSON.stringify(allProjecstData[finalProjectIndex]))
			console.log('PRIMARY DATA', allProjecstData[finalProjectIndex])
			signInSecondary(allProjecstData[finalProjectIndex]);
			const userUid = localStorage.getItem("auth_token")
			yield put(authenticated(userUid));
			yield put(showAuthMessage("Has ingresado con éxito al Administrador de Soluciones VR/AR."));
		}
	});
}



export function* signInSecondary() {
	yield takeEvery(SIGNINSECONDARY, function* ({payload}) {		
		  const primary = payload;
		  // console.log(primary)
		  try {
			  // Create secondary Firebase instance
			  const rand = Math.random() * 100000
			  const secondary = firebase.initializeApp(primary.Firebase, primary.Proyecto + rand);
			  localStorage.setItem("SECONDARY_DATA_NAME", primary.Proyecto + rand);
			  
			  secondary					
				  .auth()
				  .signInAnonymously()				  
				  .catch(function f(err3) {
					  console.log(err3)
					  // Handle Errors here.
					  // ...
				  })
				  
			  // Obtener submenús desde /Usuarios
			  const uid = localStorage.getItem("auth_token")
			  const secondaryData = yield call(FirebaseService.getSecondaryUserData, secondary, uid)
			  localStorage.setItem("SECONDARY_SUBMENU", JSON.stringify(secondaryData.val()));
			  yield put(secondaryAuthenticated(secondary));
			  
		  } catch (err) {
			  yield put(showAuthMessage(err));
		  }
	  });
  }

  export function* forget() {
	yield takeEvery(FORGET, function* ({payload}) {
		const {email} = payload;
		  try {
			  const forget = yield call(FirebaseService.forgetPassword, email);
			  if (forget === undefined) {
				// Email correcto				
				yield put(showAuthMessage("Hemos enviado a su email las instrucciones para continuar.", "success"));
			} else {
				// Email no válido
				yield put(showAuthMessage("Email no registrado. Por favor, intente de nuevo.", "error"));
			}
		  } catch (err) {
				console.log(err)
			  yield put(showAuthMessage(err));
		  }
	  });
  }




export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem("projectRef");
				yield put(signOutSuccess(signOutUser));
				if(firebase.apps.length > 1)
					firebase.apps[1].delete()
				
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signInSecondary),	
		fork(forget),	
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook)
  ]);
}
